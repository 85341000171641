export interface INavigationRoute {
  name: string
  displayName: string
  meta: {
    icon: string
    requiresSuperuser: boolean
    requiresReadOnly?: boolean
    requiresDashboardOnly?: boolean
    requiresScheduler?: boolean
    displaySidebar?: boolean
  }
  path?: string
  params?: any
  active?: boolean
  props?: boolean
  collapsed?: boolean
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'home',
      displayName: 'menu.home',
      meta: {
        icon: 'home',
        requiresSuperuser: false,
        displaySidebar: true,
      },
    },
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
        requiresSuperuser: false,
        displaySidebar: false,
      },
      children: [],
    },
    {
      name: 'scheduler',
      displayName: 'menu.scheduler',
      meta: {
        icon: 'va-calendar',
        requiresSuperuser: false,
        requiresReadOnly: false,
        displaySidebar: false,
        requiresScheduler: true,
      },
    },
    {
      name: 'faults',
      displayName: 'menu.faults',
      meta: {
        icon: 'vuestic-iconset-files',
        requiresSuperuser: true,
        requiresReadOnly: true,
        displaySidebar: false,
      },
      children: [],
    },
    {
      name: 'battery',
      displayName: 'menu.battery',
      meta: {
        icon: 'vuestic-iconset-files',
        requiresSuperuser: true,
        requiresReadOnly: true,
        displaySidebar: false,
      },
      children: [],
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
        requiresSuperuser: true,
        displaySidebar: true,
      },
    },
    // {
    //   name: 'dashboard-settings',
    //   displayName: 'menu.dashboard-settings',
    //   meta: {
    //     icon: 'vuestic-iconset-settings',
    //     requiresSuperuser: true,
    //     displaySidebar: true,
    //   },
    // },
    {
      name: 'device-settings',
      displayName: 'menu.device-settings',
      meta: {
        icon: 'vuestic-iconset-settings',
        requiresSuperuser: true,
        displaySidebar: true,
      },
    },
    {
      name: 'alerts',
      displayName: 'menu.alerts',
      meta: {
        icon: 'warning',
        requiresSuperuser: true,
        displaySidebar: true,
      },
    },
    {
      name: 'profile',
      displayName: 'menu.profile',
      meta: {
        requiresSuperuser: false,
        displaySidebar: false,
      },
    },
    // {
    //   name: 'projects',
    //   displayName: 'menu.projects',
    //   meta: {
    //     icon: 'folder_shared',
    //   },
    // },
    // {
    //   name: 'payments',
    //   displayName: 'menu.payments',
    //   meta: {
    //     icon: 'credit_card',
    //   },
    //   children: [
    //     {
    //       name: 'payment-methods',
    //       displayName: 'menu.payment-methods',
    //     },
    //     {
    //       name: 'pricing-plans',
    //       displayName: 'menu.pricing-plans',
    //     },
    //     {
    //       name: 'billing',
    //       displayName: 'menu.billing',
    //     },
    //   ],
    // },
    // {
    //   name: 'auth',
    //   displayName: 'menu.auth',
    //   meta: {
    //     icon: 'login',
    //   },
    //   children: [
    //     {
    //       name: 'login',
    //       displayName: 'menu.login',
    //     },
    //     {
    //       name: 'signup',
    //       displayName: 'menu.signup',
    //     },
    //     {
    //       name: 'recover-password',
    //       displayName: 'menu.recover-password',
    //     },
    //   ],
    // },
    //
    // {
    //   name: 'faq',
    //   displayName: 'menu.faq',
    //   meta: {
    //     icon: 'quiz',
    //   },
    // },
    // {
    //   name: '404',
    //   displayName: 'menu.404',
    //   meta: {
    //     icon: 'vuestic-iconset-files',
    //   },
    // },
    // {
    //   name: 'preferences',
    //   displayName: 'menu.preferences',
    //   meta: {
    //     icon: 'manage_accounts',
    //   },
    // },
    // {
    //   name: 'settings',
    //   displayName: 'menu.settings',
    //   meta: {
    //     icon: 'settings',
    //   },
    // },
  ] as INavigationRoute[],
}
