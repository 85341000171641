import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import { useAuthStore } from '../stores/auth'
import { useGlobalStore } from '../stores/global-store'
import { get_devices } from '../axios'

const storeRoutes = ['dashboard', 'faults', 'battery', 'scheduler']

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'home' },
    meta: { requiresAuth: true },
    children: [
      {
        name: 'home',
        path: 'home',
        component: () => import('../pages/home/Home.vue'),
        props: true,
        meta: { requiresSuper: false, requiresReadOnly: false, dashboardOnlyVisible: true },
      },
      {
        name: 'dashboard',
        path: 'dashboard/:uuid?',
        beforeEnter: (to, from, next) => {
          beforeEnterRoutes(to, next)
        },
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
        props: true,
        meta: { requiresSuper: false, requiresReadOnly: false, dashboardOnlyVisible: true, isUUID: true },
      },
      {
        name: 'scheduler',
        path: 'scheduler/:uuid?',
        beforeEnter: (to, from, next) => {
          beforeEnterRoutes(to, next)
        },
        component: () => import('../pages/scheduler/Scheduler.vue'),
        props: true,
        meta: { requiresSuper: false, requiresReadOnly: false, isUUID: true },
      },
      {
        name: 'no_dashboard',
        path: 'no_dashboard',
        component: () => import('../pages/admin/dashboard/NoDashboard.vue'),
      },
      {
        name: 'faults',
        path: 'faults/:uuid?',
        component: () => import('../pages/faults/Faults.vue'),
        meta: { requiresSuper: true, requiresReadOnly: true, isUUID: true },
        props: true,
        beforeEnter: (to, from, next) => {
          beforeEnterRoutes(to, next)
        },
      },
      // {
      //   name: 'dashboard-settings',
      //   path: 'dashboard-settings',
      //   component: () => import('../pages/admin/dashboard/DashboardSettings.vue'),
      //   meta: { requiresSuper: true, requiresReadOnly: false },
      // },
      {
        name: 'device-settings',
        path: 'device-settings',
        component: () => import('../pages/device_settings/DeviceSettings.vue'),
        meta: { requiresSuper: true, requiresReadOnly: false },
      },
      {
        name: 'alerts',
        path: 'alerts',
        component: () => import('../pages/alerts/Alerts.vue'),
        meta: { requiresSuper: true, requiresReadOnly: false },
      },
      {
        name: 'battery',
        path: 'battery/:uuid?',
        beforeEnter: (to, from, next) => {
          beforeEnterRoutes(to, next)
        },
        component: () => import('../pages/bms/BmsDashboard.vue'),
        meta: { requiresSuper: true, requiresReadOnly: true, isUUID: true },
        props: true,
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
        meta: { requiresSuper: true, requiresReadOnly: false },
      },
      {
        name: 'profile',
        path: 'profile',
        component: () => import('../pages/profile/Profile.vue'),
        meta: { requiresSuper: false, requiresReadOnly: false, dashboardOnlyVisible: true },
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'update-password',
        path: 'update-password',
        component: () => import('../pages/auth/UpdatePassword.vue'),
        props: true,
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const beforeEnterRoutes = (to: any, next: any) => {
  /*
   * Multiple sessions break the app, so we need to reset the global store
   * when the user navigates to a different dashboard.
   */
  const globalStore = useGlobalStore()
  globalStore.setIreboxUUID('')
  globalStore.setInverterType('')
  get_devices().then((response) => {
    if (response.data.length > 0) {
      // Loop through the devices and check if the irebox uuid matches the one in the URL.
      globalStore.setDevices(response.data)
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i]['irebox'] === to.params.uuid) {
          globalStore.setIreboxUUID(response.data[i]['irebox'])
          globalStore.setInverterType(response.data[i]['inverter_type'])
          next()
        }
      }
    } else {
      next({ name: 'no_dashboard' })
    }
  })
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

const checkRoutePermissions = async (to: any) => {
  let uuid = ''

  // get uuid from path if exists
  uuid = to.path.split('/')[2]
  if (uuid === undefined) {
    return false
  }

  // check if uuid is in the devices array
  const globalStore = useGlobalStore()
  const devices = globalStore.getDevices()
  if (devices.length === 0) {
    const response = await get_devices()
    if (response.data.length > 0) {
      globalStore.setDevices(response.data)
      devices.push(...response.data)
    } else {
      return false
    }
  }
  return devices.some((device: any) => device.irebox === uuid && device.web_irebox_config.modules.includes(to.name))
}

router.beforeEach(async (to, from, next) => {
  const global = useGlobalStore()
  if (!storeRoutes.includes(to.name as string)) {
    global.setIreboxUUID('')
    global.setInverterType('')
  }
  const authStore = useAuthStore()
  await authStore.initializeAuth()
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authStore.isAuthenticated()) {
      const requiresSuper = to.matched.some((record) => record.meta.requiresSuper)
      const requiresReadOnly = to.matched.some((record) => record.meta.requiresReadOnly)

      if (to.matched.some((record) => record.meta.isUUID)) {
        if (!(await checkRoutePermissions(to))) {
          next({ path: '/404' })
        }
      }

      if (authStore.isDashboardOnly()) {
        if (to.meta.dashboardOnlyVisible) {
          next()
          return
        } else {
          next({ path: '/404' })
          return
        }
      }

      if (requiresSuper && requiresReadOnly) {
        if (authStore.isSuperUser() || authStore.isReadOnly()) {
          next()
          return
        } else {
          next({ path: '/404' })
          return
        }
      }

      if (requiresSuper && !requiresReadOnly) {
        if (authStore.isSuperUser()) {
          next()
          return
        } else {
          next({ path: '/404' })
          return
        }
      }

      next()
      return
    } else {
      await authStore.logout()
      next({ path: '/auth' })
      return
    }
  } else {
    next()
    return
  }
})

export default router
